/* You can add global styles to this file, and also import other style files */

.cursor-pointer{
    cursor: pointer;
}
.btn-bg{
    background: linear-gradient(180deg, #FD7F00 0%, #E16301 100%);
    border-radius: 2px !important;
  }
  .text-orange{
    color: #FF7103;
  }
  .wh-inherit{
    width: inherit;
    height: inherit;
    object-fit:cover;
  }
  .page_wrapper {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
  .border-color{
    border: 1px solid rgba(255, 113, 3, 0.15) !important;
    border-radius: 2px !important;
  }
  .bg-blur{
    width: 268px;
    height: 268px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(65 70 96 / 58%) 100%);
    backdrop-filter: blur(4px);
    border-radius: 0px 0px 20px 20px;
    top:0px
  }
  .border-line{
    left: 15.25%;
    right: 6.25%;
    bottom: 22.83%;
  }
  .wh-10{
    width:10px;
    height: 10px;
  }
  .text-change{
    font-family: 'Raleway';
  }
  .lang-switcher {
    bottom: 0px;
    right: 81px;
    z-index: 999999;
    li {
      margin: 5px;
      opacity: .7;
    }
    .active a {
     border-bottom: 2px solid #FFFFFF;
     background-color:#FFFFFF;
     color: #3C3738 !important;
    }
  }
  .bottom-55 {
    bottom: 55px;
  }
.text-light-brown{
  color:#3C3738;
  letter-spacing: 0.5px;
}
.bg-wh{
  width: 28px;
height: 22px;
}
.vertical-row{
  height: 22px;
  width: 2px;
  background-color: #FFFFFF;
  bottom: 8px;
  left: 45px;
}
.modal{
  z-index: 1056 !important;
}
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
 