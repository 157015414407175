// Core

body {
  margin: 0 auto;
  padding: 0;
  font-family: 'Circe';
  background: #f7f7f7;
}
@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/CRC55.woff') format('woff');
}

.cursor-pointer{
  cursor: pointer;
}

@import "custom/variables";
@import "custom/icon";
@import "custom/typograpy";
@import "custom/button";
@import "custom/navbar";

@import "bootstrap/scss/bootstrap";

// regular style toast
@import 'ngx-toastr/toastr';

table tr th,table tr td {
  padding: 11px !important;
  overflow: hidden;
}

table tr th {
  text-align: left;
  width: 147px;
}

table tr {
  height:45px;

}
.table-responsive > .table-bordered {
  border:0;
}
table tr:first-child td {
  border-top: unset;
}
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.cursor-pointer{
      cursor: pointer;
  }